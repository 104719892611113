import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { BrowserView, MobileView } from 'react-device-detect'

import { Button } from 'components/ui'
import css from './block.module.scss'
import { fetchPapers } from '../actions'

import imgPaper from '../img/paper.jpg'

function PapersArchiveBlock({
  fetchPapers,
}) {
  const [statePaper, setPaper] = useState(null)
  
  useEffect(() => {
    fetchPapers('/newspaper/?page=1')
      .then(result => {
        setPaper(result.results[0])
      })
  }, [])

  return (
    <div className={css.papersArchive}>
      <div className={'container'}>
        <div className={css.content}>
          
          <div className={css.section1}>
            <div className={css.text}>
              <h2>Газета<br/>“Шабат Шалом”</h2>
            </div>
            <BrowserView renderWithFragment>
              <a
                href="https://shabbatshalom.ru/archive"
                rel="noopener noreferrer"
                className={css.button}
                target="_blank"
              >
                <Button text={'Перейти в архив'} />
              </a>
            </BrowserView>
          </div>

          <div className={css.section2}>
            {
              statePaper &&
                <React.Fragment>
                  <a
                    className={css.paper}
                    target='_blank'
                    href={statePaper.paper}
                    rel='noopener noreferrer'
                    >
                    <img
                      className={css.paperImage}
                      src={statePaper.preview}
                      alt=""
                      />
                  </a>
                  <h4 className={css.paperTitle}>{statePaper.name}</h4>
                  <p className={css.paperSubtitle}>от {statePaper.date}</p>
                </React.Fragment>
            }
            <MobileView renderWithFragment>
              <a
                href="https://shabbatshalom.ru/archive"
                rel="noopener noreferrer"
                className={css.button}
                target="_blank"
              >
                <Button text={'Перейти в архив'} />
              </a>
            </MobileView>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  null,
  dispatch => ({
    fetchPapers: bindActionCreators(fetchPapers, dispatch),
  })
)(PapersArchiveBlock)