import React from "react"
import {
  Hero,
  WhatIs,
  // MarathonBanner,
  HanukkahCalendar,
  HolidaySet,
  HolidaySetBanner,
  RelatedVideo,
  DonateBlock,
} from 'components/common'
import { PapersArchiveIndexBlock } from 'components/papers'
import { ReviewsIndexBlock } from 'components/reviews'
import { withMainLayout } from 'components/layouts'
import SEO from 'components/seo'

const IndexPage = () => (
  <React.Fragment>
    <SEO
      title='Главная страница'
    />
    {/* <MarathonBanner /> */}
    <Hero />
    <WhatIs />
    <HanukkahCalendar />
    <HolidaySet />
    <RelatedVideo />
    <HolidaySetBanner />
    <ReviewsIndexBlock />
    <PapersArchiveIndexBlock />
    <DonateBlock />
  </React.Fragment>
)

export default withMainLayout(IndexPage)
